<template>
  <b-card>
    <!-- form -->
    <validation-observer ref="createLocation">
      <b-form
          class="mt-2"
          @submit.prevent="handleSubmit"
          @keydown.enter="handleSubmit"
      >
        <b-row>
          <b-col cols="12">
            <h4 class="mb-2" >{{ $t('DetallesLocalizacion') }}</h4>
          </b-col>
          <b-col sm="6">
            <validation-provider
                #default="{ errors }"
                :name="$t('Nombre')"
                rules="required"
            >
              <b-form-group
                  :label="$t('Nombre')"
                  label-for="location-name"
              >
                <b-form-input
                    v-model="name"
                    name="name"
                    :placeholder="$t('Nombre')"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <b-form-group
                :label="$t('topLocation')"
                label-for="location-parent"
            >
              <v-select
                label="name"
                :filterable="false"
                :searchable="false"
                :options="selectLocations"
                v-model="parent"
                :placeholder="$t('topLocation')"
              />
            </b-form-group>
          </b-col>
          <b-col sm="12">
            <b-form-group
              :label="$t('Descripcion')"
              label-for="location-description"
            >
              <quill-editor
                v-model="description"
                :options="editorOption"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" class="mb-2" >
            <p>{{ $t('TextClickMap') }}</p>
            <l-map ref="map" :zoom="zoom" :center="center" @click="addMarker" >
              <l-tile-layer :url="url" />
              <l-marker
                  v-if="marker"
                  :lat-lng="marker"
              >
              </l-marker>
            </l-map>
          </b-col>
          <b-col sm="6">
            <validation-provider
                #default="{ errors }"
                :name="$t('Latitud')"
                rules="required"
            >
              <b-form-group
                  :label="$t('Latitud')"
                  label-for="location-latitude"
              >
                <b-form-input
                    v-model="latitude"
                    name="reference"
                    :placeholder="$t('Latitud')"
                    readonly
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider
                #default="{ errors }"
                :name="$t('Longitud')"
                rules="required"
            >
              <b-form-group
                  :label="$t('Longitud')"
                  label-for="location-longitude"
              >
                <b-form-input
                    v-model="longitude"
                    name="reference"
                    :placeholder="$t('Longitud')"
                    readonly
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col cols="12" >
            <validation-provider
              #default="{ errors }"
              :name="$t('Direccion')"
              rules="required"
            >
              <b-form-group
                :label="$t('Direccion')"
                label-for="location-address"
              >
                <b-form-input
                  v-model="address"
                  name="address"
                  :placeholder="$t('Direccion')"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col cols="12" class="text-right" >
            <b-button
              type="submit"
              variant="primary"
              class="mt-2 mr-1"
            >
              {{ $t('Enviar') }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard,
} from 'bootstrap-vue'
import { quillEditor } from 'vue-quill-editor'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { LMap, LMarker, LTileLayer } from "vue2-leaflet"

import { OpenStreetMapProvider, GeoSearchControl } from 'leaflet-geosearch'
import {Icon, latLng} from "leaflet"
import "leaflet/dist/leaflet.css"
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
})


export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    ValidationProvider,
    ValidationObserver,
    quillEditor,
    vSelect,
    LMap,
    LTileLayer,
    LMarker,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      zoom: 5,
      required,
      email,
      name: '',
      description: '',
      latitude: '',
      longitude: '',
      address: '',
      parent: '',
      childs: [],
      center: latLng(40.2085, -3.713),
      marker: '',
      editorOption: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],        
            ['blockquote', 'code-block'],
            [{ 'header': 1 }, { 'header': 2 }],        
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
          ],
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      currentClient: 'clients/getCurrentClient',
      selectLocations: 'locations/getSelectLocations',
    }),
  },
  methods: {
    ...mapActions({
      create: 'locations/create',
      getLocation: 'locations/getLocation',
      getSelectLocations: 'locations/getSelectLocations',
    }),
    addMarker(event) {
      this.latitude = event.latlng.lat
      this.longitude = event.latlng.lng
      this.marker = event.latlng
    },
    handleSubmit() {
      this.$refs.createLocation.validate().then(success => {
        if (success) {
          const formData = this.createFormData()
          if (formData) {
            this.create({ location: formData })
          }
        }
      })
    },
    createFormData() {
      const data = new FormData()
      if (this.parent) {
        data.append('parent', this.parent.id)
      } else {
        data.append('parent', '')
      }
      if (this.childs.length) {
        this.childs.forEach(e => {
          data.append('childs[]', e.id)
        })
      } else {
        data.append('childs', '')
      }
      data.append('name', this.name)
      data.append('latitude', this.latitude)
      data.append('longitude', this.longitude)
      data.append('description', this.description)
      data.append('address', this.address)
      data.append('client_id', this.currentClient.id)
      data.append('out', 0)

      return data
    },
    searchEventHandler(result) {
      this.address = result.location.label
    },
  },
  async created() {
    await this.getSelectLocations({ client: this.currentClient.id })
  },
  mounted() {
    const map = this.$refs.map.mapObject
    const searchControl = new GeoSearchControl({
      provider: new OpenStreetMapProvider(),
      showMarker: false,
      // ... some more options
    })
    map.addControl(searchControl)
    map.on('geosearch/showlocation', this.searchEventHandler)
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.vue2leaflet-map {
  &.leaflet-container {
    height: 450px;
  }
}
</style>
